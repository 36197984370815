import React from 'react';
import { useNavigate } from 'react-router-dom';
import './KYH.css'; 

const Brain = () => {
  const navigate = useNavigate();  // useNavigate hook to handle navigation
    const handleBackClick = () => {
        navigate(-1);  // This will navigate back to the previous page
      };
    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
          <h1 className='kyh-heading'>Brain</h1>
          
        </div>
    );
};

export default Brain;
