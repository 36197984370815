// src/KYH/MenstrualHealth.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KYH.css'; // Link your CSS file here

const MenstrualHealth = () => {
    const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };

  const [showTips, setShowTips] = useState(false);

  const toggleTips = () => {
    setShowTips(!showTips);
  };

  return (
    <div>
    <div className="back-arrow" onClick={handleBackClick}>
        ← Women Health
    </div>
    <div className="kyh-menstrual-health-container">
      <h2>Menstrual Health and Well-being</h2>

      {/* Menstrual Cycle Overview */}
      <div className="kyh-menstrual-card">
        <h3>Menstrual Cycle Overview</h3>
        <div className="kyh-cycle-details">
          <p>Cycle Length: <strong>21 to 35 days</strong></p>
          <p>Menstruation: <strong>3 to 7 days</strong></p>
          <p>Phases:</p>
          <ul>
            <li>Menstrual Phase</li>
            <li>Follicular Phase</li>
            <li>Ovulation</li>
            <li>Luteal Phase</li>
          </ul>
        </div>
      </div>

      {/* Irregular Periods */}
      <div className="kyh-menstrual-card">
        <h3>Irregular Periods</h3>
        <div className="kyh-irregular-details">
          <p>Common Causes:</p>
          <ul>
            <li>Stress</li>
            <li>Hormonal Imbalances</li>
            <li>Polycystic Ovary Syndrome (PCOS)</li>
            <li>Thyroid Issues</li>
            <li>Weight Changes</li>
          </ul>
          <p>Symptoms:</p>
          <ul>
            <li>Heavy Bleeding</li>
            <li>Skipped Periods</li>
            <li>Painful Cramps</li>
          </ul>
        </div>
      </div>

      {/* Tips for Menstrual Health */}
      <div className="kyh-menstrual-card">
        <h3 className="kyh-tips-header" onClick={toggleTips}>
          {showTips ? "Hide Tips for Menstrual Health" : "Show Tips for Menstrual Health"}
        </h3>
        {showTips && (
          <div className="kyh-tips-details">
            <ul>
              <li>Maintain a balanced diet rich in iron and vitamins.</li>
              <li>Exercise regularly to help regulate the menstrual cycle.</li>
              <li>Practice stress management techniques like yoga or meditation.</li>
            </ul>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default MenstrualHealth;
