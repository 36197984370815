import React, { useState } from 'react';
import axios from 'axios';
import './Doctor.css'; // Import the CSS file

const DoctorList = () => {
  const doctors = [
    { id: 1, name: 'Dr. John Doe', specialization: 'Cardiologist', experience: '10 years', location: 'New York' },
    { id: 2, name: 'Dr. Jane Smith', specialization: 'Dermatologist', experience: '7 years', location: 'Los Angeles' },
    { id: 3, name: 'Dr. Emily Johnson', specialization: 'Pediatrician', experience: '15 years', location: 'San Francisco' },
    { id: 4, name: 'Dr. John Haddon', specialization: 'Cardiologist', experience: '6 years', location: 'San Francisco' },
  ];

  const [email, setEmail] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [message, setMessage] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleBookAppointment = async (doctor) => {
    if (!email || !appointmentTime) {
      setMessage('Please provide an email and choose a time.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/book-appointment', {
        doctorName: doctor.name,
        userEmail: email,
        appointmentTime,
      });

      // Show success message and zoom link (mocked in backend)
      setMessage(`Appointment booked!`);

      // Optionally, clear inputs
      setEmail('');
      setAppointmentTime('');
    } catch (error) {
      setMessage('Failed to book appointment. Please try again.');
    }
  };

  return (
    <div className="doctor-list">
      <h1>Book an Appointment</h1>

      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Appointment Time:</label>
        <input
          type="datetime-local"
          value={appointmentTime}
          onChange={(e) => setAppointmentTime(e.target.value)}
        />
      </div>

      <div className="doctor-cards">
        {doctors.map((doctor) => (
          <div key={doctor.id} className="doctor-card">
            <h2>{doctor.name}</h2>
            <p><strong>Specialization:</strong> {doctor.specialization}</p>
            <p><strong>Experience:</strong> {doctor.experience}</p>
            <p><strong>Location:</strong> {doctor.location}</p>
            <button className="book-btn" onClick={() => handleBookAppointment(doctor)}>
              Book Appointment
            </button>
          </div>
        ))}
      </div>

      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default DoctorList;
