// src/KYH/BMIChartCalculator.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KYH.css'; // Ensure this file includes the updated styles

const BMIChartCalculator = () => {
  const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };
          
  const [bmi, setBmi] = useState(null);
  const [status, setStatus] = useState('');
  const [colorClass, setColorClass] = useState('');

  const calculateBMI = (e) => {
    e.preventDefault();
    const height = parseFloat(e.target.height.value) / 100; // Convert height to meters
    const weight = parseFloat(e.target.weight.value);
    if (height > 0 && weight > 0) {
      const bmiValue = (weight / (height * height)).toFixed(1);
      setBmi(bmiValue);

      let bmiStatus = '';
      let bmiColorClass = '';

      if (bmiValue < 18.5) {
        bmiStatus = 'Underweight';
        bmiColorClass = 'underweight';
      } else if (bmiValue >= 18.5 && bmiValue <= 24.9) {
        bmiStatus = 'Normal weight';
        bmiColorClass = 'normal-weight';
      } else if (bmiValue >= 25 && bmiValue <= 29.9) {
        bmiStatus = 'Overweight';
        bmiColorClass = 'overweight';
      } else {
        bmiStatus = 'Obesity';
        bmiColorClass = 'obesity';
      }
      setStatus(bmiStatus);
      setColorClass(bmiColorClass);
    }
  };

  return (
    <div className="bmi-chart-calculator">
      <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
      <h2 className='chart-heading'>BMI Chart</h2>

      {/* BMI Table */}
      <table className="bmi-chart-table">
        <thead>
          <tr>
            <th>BMI Category</th>
            <th>BMI Range</th>
          </tr>
        </thead>
        <tbody>
          <tr className="underweight">
            <td>Underweight</td>
            <td>Less than 18.5</td>
          </tr>
          <tr className="normal-weight">
            <td>Normal weight</td>
            <td>18.5–24.9</td>
          </tr>
          <tr className="overweight">
            <td>Overweight</td>
            <td>25–29.9</td>
          </tr>
          <tr className="obesity">
            <td>Obesity</td>
            <td>30 or higher</td>
          </tr>
        </tbody>
      </table>

      {/* BMI Calculator */}
      <h2 className='calculator-heading'>BMI Calculator</h2>
      <form className="bmi-calculator" onSubmit={calculateBMI}>
        <div className="input-group">
          <label htmlFor="height">Height (cm):</label>
          <input type="number" id="height" name="height" required />
        </div>
        <div className="input-group">
          <label htmlFor="weight">Weight (kg):</label>
          <input type="number" id="weight" name="weight" required />
        </div>
        <button type="submit" className="calculate-btn">Calculate BMI</button>
      </form>

      {/* Display Result */}
      {bmi && (
        <div className={`bmi-result-container ${colorClass}`}>
          <div className="bmi-result">
            <h4>Your BMI:</h4>
            <p><strong>{bmi}</strong></p>
          </div>
          <div className="bmi-status">
            <h4>Status:</h4>
            <p><strong>{status}</strong></p>
          </div>
        </div>
      )}

      {/* Color-coded BMI scale */}
      {/* <div className="bmi-scale">
        <div className="scale-segment underweight">Underweight</div>
        <div className="scale-segment normal-weight">Normal weight</div>
        <div className="scale-segment overweight">Overweight</div>
        <div className="scale-segment obesity">Obesity</div>
      </div> */}
    </div>
  );
};

export default BMIChartCalculator;
