import React from 'react';
import './SubscriptionPage.css';

const SubscriptionPage = () => {
  return (
    <div className="subscription-page">
      <h1 className="page-title">Choose Your Plan</h1>
      <div className="subscription-container">
        {/* Business Subscription Plans */}
        <div className="subscription-category">
          <h2>For Business</h2>
          <div className="pricing-table">
            <table>
              <thead>
                <tr>
                  <th>Features</th>
                  <th>Silver</th>
                  <th>Gold</th>
                  <th>Platinum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EMR</td>
                  <td>&#10003;</td> {/* Checkmark */}
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                </tr>
                <tr>
                  <td>Applets</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                </tr>
                <tr>
                  <td>Device Integration</td>
                  <td>&#10007;</td> {/* Cross */}
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                </tr>
                <tr>
                  <td>Telemedicine</td>
                  <td>&#10007;</td>
                  <td>&#10007;</td>
                  <td>&#10003;</td>
                </tr>
                <tr>
                  <td>Analytics</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Doctor Subscription Plans */}
        <div className="subscription-category">
          <h2>For Doctors</h2>
          <div className="pricing-table">
            <table>
              <thead>
                <tr>
                  <th>Features</th>
                  <th>Silver</th>
                  <th>Gold</th>
                  <th>Platinum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Applets</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                </tr>
                <tr>
                  <td>Telemedicine</td>
                  <td>&#10007;</td>
                  <td>&#10007;</td>
                  <td>&#10003;</td>
                </tr>
                <tr>
                  <td>Analytics</td>
                  <td>&#10007;</td>
                  <td>&#10003;</td>
                  <td>&#10003;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Free for Users */}
        <div className="subscription-category">
          <h2>For Users</h2>
          <p className="free-text">Free for all users</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
