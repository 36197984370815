import React from 'react';
import './ReceptionistDashboard.css';

const ReceptionistDashboard = () => {

  return (
    <div className="reception-dashboard">
      <nav className="reception-sidebar">
          <h2>Quick Links</h2>
            <ul>
              <li>Search Patient</li>
              <li>Previous Appointments</li>
            </ul>
          </nav>
          <main className="reception-content">
            <div className="greeting">
              <h2>Upcoming Appointments</h2>
            </div>
          </main>
        </div>
      );
  };


export default ReceptionistDashboard;

