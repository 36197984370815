import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import pregnancyIcon from './assets/icons/embryo.png';
import menstrualHealth from './assets/icons/menstrual-cycle.png';
import { ReactComponent as EyeSightIcon } from '../assets/icons/eye.svg';
import { ReactComponent as WomenIcon } from '../assets/icons/women.svg';

function WomenHealth() {
    const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };
    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
          <h1 className='kyh-heading'>Women Health</h1>
          <div className="kyh-row1">
            <div className="kyh-card">
              <Link to="/menstrual-health">
                <img src={menstrualHealth} alt="menstrualHealth" className="women-image" />
                <h3>Menstrual Health</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/pregnancy">
              <img src={pregnancyIcon} alt="Pregnant Icon" className="women-image" />
                <h3>Pregnancy</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/menopause">
                <WomenIcon className="kyh-image" />
                <h3>Menopause</h3>
              </Link>
            </div>
          </div>
          <div className="kyh-row2">
            <div className="kyh-card">
              <Link to="/breast-health">
                <EyeSightIcon className="kyh-image" />
                <h3>Anaemia</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/breast-health">
                <EyeSightIcon className="kyh-image" />
                <h3>PCOS</h3>
              </Link>
            </div>
          </div>

        </div>
    );
}

export default WomenHealth;