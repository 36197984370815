import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import DoctorDashboard from './dashboard/DoctorDashboard';
import ReceptionistDashboard from './dashboard/ReceptionistDashboard';
import Checkup from './Checkup';
import BMIChartCalculator from './kyh/BMIChartCalculator';
import HealthIndicators from './kyh/HealthIndicators';
import KYH from './kyh/KYH'; // Import KYH component
import Contact from './Contact'; // Import Contact component
import LandingPage from './LandingPage';
import SubscriptionPage from './SubscriptionPage';
import BlogPage from './BlogPage';
import WomenHealth from './kyh/WomenHealth';
import MenstrualHealth from './kyh/MenstrualHealth';
import Pregnancy from './kyh/Pregnancy';
import Menopause from './kyh/Menopause';
import BreastHealth from './kyh/BreastHealth';
import EyeCare from './kyh/EyeCare';
import CancerSymptoms from './kyh/CancerSymptoms';
import ChildCare from './kyh/ChildCare';
import Blood from './kyh/Blood';
import Nutrition from './kyh/Nutrition';
import FirstAid from './kyh/FirstAid';
import RespiratoryHealth from './kyh/RespiratoryHealth';
import LiverHealth from './kyh/Liver';
import SkinAllergy from './kyh/SkinAllergy';
import Brain from './kyh/Brain';
import MentalHealth from './kyh/MentalHealth';
import Thyroidism from './kyh/Thyroidism';
import DoctorBooking from './doctor/DoctorList';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('');

  const handleLogin = (userRole) => {
    setIsAuthenticated(true);
    setRole(userRole);
  };

  return (
    <Router>
      <div className="App">
        <Navigation isAuthenticated={isAuthenticated} role={role} />
        <main>
          {isAuthenticated ? (
            <>
              {role === 'doctor' ? (
                <Routes>
                  <Route path="/doctor-dashboard" element={<DoctorDashboard doctor={{ name: 'John Doe', specialization: 'Cardiology', patientsTreated: 120 }} />} />
                  <Route path="/checkup" element={<Checkup />} />
                  <Route path="/bmi-chart-calculator" element={<BMIChartCalculator />} />
                  <Route path="/health-indicators" element={<HealthIndicators />} />
                  <Route path="/kyh" element={<KYH />} />
                  <Route path="*" element={<Navigate to="/doctor-dashboard" />} />
                </Routes>
              ) : role === 'receptionist' ? (
                <Routes>
                  <Route path="/receptionist-dashboard" element={<ReceptionistDashboard />} />
                  <Route path="/checkup" element={<Checkup />} />
                  <Route path="/bmi-chart-calculator" element={<BMIChartCalculator />} />
                  <Route path="/health-indicators" element={<HealthIndicators />} />
                  <Route path="/kyh" element={<KYH />} />
                  <Route path="*" element={<Navigate to="/receptionist-dashboard" />} />
                </Routes>
              ) : (
                <Navigate to="/" /> // Navigate back to home if role is undefined
              )}
            </>
          ) : (
            <Routes>
              <Route path="/" element={<LandingPage />} /> {/* Landing page */}
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/subscriptions" element={<SubscriptionPage />} />
              <Route path="/blogs" element={<BlogPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/checkup" element={<Checkup />} />
              <Route path="/bmi-chart-calculator" element={<BMIChartCalculator />} />
              <Route path="/health-indicators" element={<HealthIndicators />} />
              <Route path="/kyh" element={<KYH />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to home for undefined routes */}
              <Route path="/women-health" element={<WomenHealth />}></Route>
              <Route path="menstrual-health" element={<MenstrualHealth />}></Route>
              <Route path="/pregnancy" element={<Pregnancy />}></Route>
              <Route path="/menopause" element={<Menopause />}></Route>
              <Route path="/breast-health" element={<BreastHealth />}></Route>
              <Route path="/eye-care" element={<EyeCare />}></Route>
              <Route path="/cancer" element={<CancerSymptoms />}></Route>
              <Route path="/child-care" element={<ChildCare />}></Route>
              <Route path="/blood" element={<Blood />}></Route>
              <Route path="/nutrition" element={<Nutrition />}></Route>
              <Route path="/first-aid" element={<FirstAid />}></Route>
              <Route path="/respiratory-health" element={<RespiratoryHealth />}></Route>
              <Route path="/liver-health" element={<LiverHealth />}></Route>
              <Route path="/skin" element={<SkinAllergy />}></Route>
              <Route path="/brain" element={<Brain />}></Route>
              <Route path="/mental-health" element={<MentalHealth />}></Route>
              <Route path="/thyroidism" element={<Thyroidism />}></Route>
              <Route path="/doctors" element={<DoctorBooking />}></Route>
            </Routes>
          )}
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
