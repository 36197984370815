// Checkup.js
import React from 'react';
import './App.css'; // Import the main CSS file
import xray from './assets/xray.jpeg';
import cataract from './assets/cataract.png';
import heart from './assets/heart.jpg';

const Checkup = () => {
  return (
    
      <div className="check-up-container">
        <div className="main-content">
          <h2 className='check-up-heading'>Services</h2>
          <div className="check-up-card-container">
            <a href="xray.html" className="check-up-card">
              <img src={xray} alt="X-Ray Detection" />
              <div className="check-up-card-info">
                <h3>Fracture Detection</h3>
                <p>Explore our advanced Fracture detection services.</p>
              </div>
            </a>
            <a href="heart.html" className="check-up-card">
              <img src={heart} alt="Heart Disease Prediction" />
              <div className="check-up-card-info">
                <h3>Heart Disease Prediction</h3>
                <p>Get insights into your heart health with our prediction service.</p>
              </div>
            </a>
            <a href="cataract.html" className="check-up-card">
              <img src={cataract} alt="Cataract Eye Detection" />
              <div className="check-up-card-info">
                <h3>Cataract Eye Detection</h3>
                <p>Discover our cutting-edge cataract detection technology.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
  );
};

export default Checkup;
