import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BmiIcon } from '../assets/icons/bmi.svg';
import { ReactComponent as HealthIcon } from '../assets/icons/HI.svg';
import { ReactComponent as ColorIcon } from '../assets/icons/bmi.svg';
import { ReactComponent as EyeSightIcon } from '../assets/icons/eye.svg';
import { ReactComponent as CancerIcon } from '../assets/icons/cancer.svg';
import { ReactComponent as SkinIcon } from '../assets/icons/skin_allergy.svg';
import { ReactComponent as AnaemiaIcon } from '../assets/icons/anaemia.svg';
import { ReactComponent as NutritionIcon } from '../assets/icons/diet.svg';
import { ReactComponent as FirstAidIcon } from '../assets/icons/first_aid.svg';
import { ReactComponent as WomenIcon } from '../assets/icons/women.svg';
import { ReactComponent as MentalHealthIcon } from '../assets/icons/mental_health_counsellor.svg';

import './KYH.css';

const KYH = () => {
  return (
    <div className="kyh-container">
      <h1 className='kyh-heading'>Know Your Health (KYH)</h1>
      <div className="kyh-row1">
        <div className="kyh-card">
          <Link to="/bmi-chart-calculator">
            <BmiIcon className="kyh-image" />
            <h3>BMI Calculator</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/health-indicators">
            <HealthIcon className="kyh-image" />
            <h3>Health Indicators</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/women-health">
            <WomenIcon className="kyh-image" />
            <h3>Women's Health</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row2">
        <div className="kyh-card">
          <Link to="/eye-care">
            <EyeSightIcon className="kyh-image" />
            <h3>Eye Care</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/cancer">
            <CancerIcon className="kyh-image" />
            <h3>Cancer Symptoms</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/child-care">
            <SkinIcon className="kyh-image" />
            <h3>Child Care</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row3">
        <div className="kyh-card">
          <Link to="/blood">
            <AnaemiaIcon className="kyh-image" />
            <h3>Blood</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/nutrition">
            <NutritionIcon className="kyh-image" />
            <h3>Nutrition and Diet</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/first-aid">
            <FirstAidIcon className="kyh-image" />
            <h3>First Aid and Emergency</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row4">
      <div className="kyh-card">
          <Link to="/skin">
            <SkinIcon className="kyh-image" />
            <h3>Skin Allergy</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/mental-health">
            <MentalHealthIcon className="kyh-image" />
            <h3>Mental Health Assessment</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/respiratory-health">
            <MentalHealthIcon className="kyh-image" />
            <h3>Respiratory Health</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row5">
      <div className="kyh-card">
          <Link to="/liver-health">
            <ColorIcon className="kyh-image" />
            <h3>Liver Health</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/brain">
            <MentalHealthIcon className="kyh-image" />
            <h3>Brain</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/thyroidism">
            <MentalHealthIcon className="kyh-image" />
            <h3>Thyroidism</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-appointment-button">
        <Link to="/doctors">
          <button className="appointment-button">Book an Appointment</button>
        </Link>
      </div>
    </div>
  );
};

export default KYH;
