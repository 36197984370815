// src/KYH/HealthIndicators.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './KYH.css'; // Make sure this path is correct and the CSS file is properly linked

const HealthIndicators = () => {
  const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };

  const healthData = [
    {
      indicator: 'Blood Pressure',
      normal: '120/80 mmHg',
      precondition: '120–139/80–89 mmHg',
      abnormal: '140/90 mmHg or higher',
    },
    {
      indicator: 'Cholesterol (Total)',
      normal: 'Less than 200 mg/dL',
      abnormal: '200 mg/dL or higher',
    },
    {
      indicator: 'LDL (Bad Cholesterol)',
      normal: 'Less than 100 mg/dL',
      abnormal: '100 mg/dL or higher',
    },
    {
      indicator: 'HDL (Good Cholesterol)',
      normal: '60 mg/dL or higher',
      abnormal: 'Less than 60 mg/dL',
    },
    {
      indicator: 'Triglycerides',
      normal: 'Less than 150 mg/dL',
      abnormal: '150 mg/dL or higher',
    },
    {
      indicator: 'Blood Sugar (Fasting)',
      normal: '70–99 mg/dL',
      precondition: '100–125 mg/dL',
      abnormal: '126 mg/dL or higher',
    },
    {
      indicator: 'Hemoglobin (Men)',
      normal: '13.8–17.2 g/dL',
      abnormal: 'Below 13.8 or above 17.2 g/dL',
    },
    {
      indicator: 'Hemoglobin (Women)',
      normal: '12.1–15.1 g/dL',
      abnormal: 'Below 12.1 or above 15.1 g/dL',
    },
    {
      indicator: 'Heart Rate',
      normal: '60–100 bpm',
      abnormal: 'Below 60 or above 100 bpm',
    },
  ];

  return (
    <div className="kyh-health-indicators">
      <div className="back-arrow" onClick={handleBackClick}>
        ← KYH
      </div>
      <h2 className='hi-heading'>Normal and Abnormal Values for Key Health Indicators</h2>
      <table className="kyh-health-indicators-table">
        <thead>
          <tr>
            <th>Health Indicator</th>
            <th>Normal Range</th>
            <th>Pre-condition Range</th>
            <th>Abnormal Range</th>
          </tr>
        </thead>
        <tbody>
          {healthData.map((data, index) => (
            <tr key={index}>
              <td>{data.indicator}</td>
              <td className="kyh-normal-range">{data.normal}</td>
              <td className="kyh-precondition-range">{data.precondition || 'N/A'}</td>
              <td className="kyh-abnormal-range">{data.abnormal}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HealthIndicators;
