// src/Navigation.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './App.css';
import logo from './assets/logo.jpg';

const Navigation = ({ isAuthenticated, role }) => {
  const location = useLocation();

  return (
    <header>
      <div className="nav-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <nav className="nav-links">
          <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
          <Link to="/checkup" className={location.pathname === '/checkup' ? 'active' : ''}>Applets</Link>
          <Link to="/kyh" className={location.pathname === '/kyh' ? 'active' : ''}>KYH</Link>
          <Link to="/subscriptions" className={location.pathname === '/subscriptions' ? 'active' : ''}>Pricing</Link>
          <Link to="/blogs" className={location.pathname === '/blogs' ? 'active' : ''}>Blogs</Link>
          <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact Us</Link>
        </nav>
        <div className="login-button-container">
          {isAuthenticated ? (
            <span>{role === 'doctor' ? 'Doctor' : 'Receptionist'}</span>
          ) : (
            <Link to="/login" className="login-button">Log In</Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navigation;
