import React from 'react'
import { useNavigate } from 'react-router-dom';

function BreastHealth() {
    const navigate = useNavigate();  // useNavigate hook to handle navigation

    const handleBackClick = () => {
        navigate(-1);  // This will navigate back to the previous page
    };
    
    return (
        <div className='kyh-breast-health-container'>
            <div className="back-arrow" onClick={handleBackClick}>
                ← Women Health
            </div>
        </div>
    )
}

export default BreastHealth;