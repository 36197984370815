import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { ReactComponent as CancerIcon } from '../assets/icons/cancer.svg';

function CancerSymptoms() {
    const navigate = useNavigate();  // useNavigate hook to handle navigation

    const handleBackClick = () => {
        navigate(-1);  // This will navigate back to the previous page
    };

    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
        <h1 className='kyh-heading'>Cancer Types</h1>
        <div className="kyh-row1">
        <div className="kyh-card">
            <Link to="/bmi-chart-calculator">
            <CancerIcon className="kyh-image" />
            <h3>Thyroid</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/health-indicators">
            <CancerIcon className="kyh-image" />
            <h3>Breast</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/women-health">
            <CancerIcon className="kyh-image" />
            <h3>Blood</h3>
            </Link>
        </div>
        </div>
        <div className="kyh-row2">
        <div className="kyh-card">
            <Link to="/eye-care">
            <CancerIcon className="kyh-image" />
            <h3>Bladder</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/eye-sight-test">
            <CancerIcon className="kyh-image" />
            <h3>Bone</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/eye-sight-test">
            <CancerIcon className="kyh-image" />
            <h3>Stomach</h3>
            </Link>
        </div>
        </div>
        <div className="kyh-row3">
        <div className="kyh-card">
            <Link to="/eye-sight-test">
            <CancerIcon className="kyh-image" />
            <h3>Liver</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/eye-sight-test">
            <CancerIcon className="kyh-image" />
            <h3>Skin</h3>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default CancerSymptoms