import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './LandingPage.css'; 
import intro from './assets/intro.png';
import lottie from 'lottie-web'; // Import Lottie Web
import animationData from './assets/animations/hospital.json'; // Import your Lottie JSON animation
import animationDataDoc from './assets/animations/doctor.json';

const LandingPage = () => {
  useEffect(() => {
    const animationContainer = document.querySelector('#lottie-animation');
    const animationContainerDoc = document.querySelector('#lottie-animation-doc');

    // Load the Lottie animation
    const animation = lottie.loadAnimation({
      container: animationContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData, // Replace with your animation data
    });

    const animationDoc = lottie.loadAnimation({
      container: animationContainerDoc,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationDataDoc, // Replace with your animation data
    });

    return () => {
      animation.destroy(); // Clean up the animation on component unmount
      animationDoc.destroy();
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('card-visible');
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    const cards = document.querySelectorAll('.card-animated');
    cards.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      cards.forEach((card) => {
        observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div className="container-fluid landing-page"> {/* Bootstrap container for responsive layout */}
      {/* Bubbles */}
      <div className="bubble bubble-1"></div>
      <div className="bubble bubble-2"></div>
      <div className="bubble bubble-3"></div>
      <div className="bubble bubble-4"></div>
      <div className="bubble bubble-5"></div>

      {/* Introduction Section */}
      <section className="intro-section row align-items-center"> {/* Bootstrap row */}
        <div className="col-md-6 intro-content"> {/* Bootstrap column for text */}
          <h2 className='heading-font'>Welcome to Hsuite</h2>
          <p className="lead"> {/* Bootstrap's lead class for prominent text */}
            Unlock the future of healthcare with our innovative AI-driven solutions.
            Get your suite of tools that streamline consultations, unify medical records, 
            and empower healthcare professionals for better patient outcomes!
          </p>
          <div className="intro-buttons">
            <Link className="btn btn-primary btn-lg" to="/signup"> {/* Bootstrap button */}
              Get Started
            </Link>
          </div>
        </div>
        <div className="col-md-6 intro-image text-center"> {/* Bootstrap column for image */}
          <img src={intro} alt="Introduction" className="img-fluid rounded" /> {/* Bootstrap responsive image */}
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section text-center">
        <h2>What We Offer</h2>
        <div className="row benefit-cards">
          <div className="col-md-6 mb-4">
            <div className="d-flex">
              <div className="benefit-image" id="lottie-animation">
                {/* Lottie animation will be rendered here */}
              </div>
              <div className="benefit-points" style={{textAlign: 'left', marginLeft: '20px' }}>
                <h3 className="card-title">Hospitals</h3>
                <ul className="list-unstyled">
                  <li><img src={intro} alt="Icon 1" className="benefit-icon" /> Electronic Medical Records (EMR)</li>
                  <li><img src={intro} alt="Icon 2" className="benefit-icon" /> AI-Driven Application Suites</li>
                  <li><img src={intro} alt="Icon 3" className="benefit-icon" /> Healthcare Analytics Solutions</li>
                  <li><img src={intro} alt="Icon 4" className="benefit-icon" /> Device Automation and Integration</li>
                  <li><img src={intro} alt="Icon 5" className="benefit-icon" /> Telemedicine Consultation Platform</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="d-flex">
              <div className="benefit-image" id="lottie-animation-doc">
                {/* Lottie animation will be rendered here */}
              </div>
              <div className="benefit-points" style={{textAlign: 'left', marginLeft: '20px' }}>
                <h3 className="card-title">Doctors</h3>
                <ul className="list-unstyled">
                  <li><img src={intro} alt="Icon 1" className="benefit-icon" /> AI-driven Application Tools</li>
                  <li><img src={intro} alt="Icon 2" className="benefit-icon" /> Comprehensive Case Management System</li>
                  <li><img src={intro} alt="Icon 3" className="benefit-icon" /> Advanced Analytics Tools</li>
                  <li><img src={intro} alt="Icon 4" className="benefit-icon" /> Virtual Consultation Platform</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="d-flex">
              <div className="benefit-image" id="lottie-animation">
                {/* Lottie animation will be rendered here */}
              </div>
              <div className="benefit-points" style={{textAlign: 'left', marginLeft: '20px' }}>
                <h3 className="card-title">Patients</h3>
                <ul className="list-unstyled">
                  <li><img src={intro} alt="Icon 1" className="benefit-icon" /> Virtual Mental Health Counselor</li>
                  <li><img src={intro} alt="Icon 2" className="benefit-icon" /> Remote Consultation Services</li>
                  <li><img src={intro} alt="Icon 3" className="benefit-icon" /> Diet Recommendation System</li>
                  <li><img src={intro} alt="Icon 4" className="benefit-icon" /> Know Your Health (KYH)</li>
                  <li><img src={intro} alt="Icon 5" className="benefit-icon" /> Medication Management and Reminders</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Vision and Mission Section */}
      <section className="vision-mission-section">
        <h2>Our Vision and Mission</h2>
        <div className="vision-mission-cards">
          <div className="vision-mission-card card-animated">
            <h3>Our Vision</h3>
            <p>
              To be the leading provider of AI-driven healthcare solutions that seamlessly amalgamates artificial intelligence into the healthcare ecosystem, thereby accelerating diagnostic processes and fundamentally transforming patient care on a global scale. We envision a future where our innovative technologies empower healthcare professionals to make informed decisions quickly, enhance collaboration among specialists, and provide personalized care to every patient. Through continuous advancements in AI, we aspire to improve health outcomes, reduce treatment delays, and ensure equitable access to quality healthcare for all. Our commitment to excellence and innovation will drive us to set new standards in the healthcare industry, making us the go-to partner for hospitals, doctors, and patients alike.
            </p>
          </div>
          <div className="vision-mission-card card-animated">
            <h3>Our Mission</h3>
            <p>
              To deliver innovative AI tools that minimize delays from internal department consultations, enhance interoperability among medical databases, and unify medical records with telemedicine services. Our mission is to empower healthcare professionals, streamline patient management, and improve patient outcomes through seamless communication and collaboration.
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2>What Our Users Say</h2>
        <div className="testimonials-cards">
          <div className="testimonial-card card-animated">
            <p>
              "Hsuite has transformed the way I manage my patients. The AI tools are
              intuitive and save me so much time!" - Dr. John Smith
            </p>
          </div>
          <div className="testimonial-card card-animated">
            <p>
              "I love the telemedicine feature! It's so easy to connect with my
              doctor." - Sarah Johnson
            </p>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="contact-us-section text-center">
        <h2>Contact Us</h2>
        <p>If you have any questions, feel free to reach out!</p>
        <Link className="btn btn-primary" to="/contact">Contact Us</Link>
      </section>
    </div>
  );
};

export default LandingPage;
