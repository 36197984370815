// Contact.js
import React from 'react';
import './App.css';

const Contact = () => {
  return (
    <main>
      <section className="contact-section" id="contact-us"> {/* Add an id to the section */}
        <h2 className='contact-us-heading'>Contact Us</h2>
        <form className="contact-form">
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit">Send</button>
        </form>
      </section>
    </main>
  );
};

export default Contact;
