import React from 'react';
import './BlogPage.css';

const BlogPage = () => {
  return (
    <div className="blog-page">
      <div className="coming-soon-container">
        <h1>Our Blog is Coming Soon!</h1>
        <p>We're working hard to bring you insightful articles, industry trends, and healthcare tips. Stay tuned!</p>
        <p>In the meantime, feel free to explore our platform and get started with Hsuite!</p>
        <button className="explore-button" onClick={() => window.location.href = '/'}>Explore Hsuite</button>
      </div>
    </div>
  );
};

export default BlogPage;
