import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://h-suite.vercel.app/api/login', // Your backend URL
        { username, password }, // Payload
        { withCredentials: true } // Ensure credentials like cookies are allowed
      );

      const data = response.data;
      setMessage('Login successful');
      setMessageType('success');
      onLogin(data.role); // Pass the role to the parent component

      // Navigate based on the role
      if (data.role === 'doctor') {
        navigate('/doctor-dashboard'); // Redirect to doctor's dashboard
      } else {
        navigate('/'); // Redirect to home for other roles
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status code out of the range 2xx
        setMessage(`Login failed: ${error.response.data.message || 'Unknown error'}`);
        setMessageType('error');
      } else {
        // Something else went wrong (network, etc.)
        setMessage(`An error occurred: ${error.message}`);
        setMessageType('error');
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
          {message && (
            <div className={`message ${messageType}`} style={{ marginTop: '10px' }}>
              {message}
            </div>
          )}
        </form>
        <p>
          New user? <Link to="/signup">Sign up here</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
