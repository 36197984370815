import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './KYH.css'; 
import { ReactComponent as AnaemiaIcon } from '../assets/icons/anaemia.svg';

const Blood = () => {
  const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };
    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
          <h1 className='kyh-heading'>Eye Care</h1>
          <div className="kyh-row1">
            <div className="kyh-card">
              <Link to="/menstrual-health">
                <AnaemiaIcon className="kyh-image" />
                <h3>Anaemia</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/pregnancy">
              <AnaemiaIcon className="kyh-image" />
                <h3>Blood Banks</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/pregnancy">
              <AnaemiaIcon className="kyh-image" />
                <h3>Blood Donor Chart</h3>
              </Link>
            </div>
          </div>
          <div className="kyh-row2">
            <div className="kyh-card">
              <Link to="/menstrual-health">
                <AnaemiaIcon className="kyh-image" />
                <h3>Blood Test Types</h3>
              </Link>
            </div>
          </div>
        </div>
    );
};

export default Blood;
