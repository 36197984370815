import React from 'react';
import './DoctorDashboard.css';

const DoctorDashboard = ({ doctor }) => {
  const currentHour = new Date().getHours();
  const greeting =
    currentHour < 12
      ? 'Good Morning'
      : currentHour < 18
      ? 'Good Afternoon'
      : 'Good Evening';


  return (
    <div className="doctor-dashboard">
      <nav className="sidebar">
        <h2>Quick Links</h2>
        <ul>
          <li>Search Patient</li>
          <li>Previous Appointments</li>
        </ul>
      </nav>
      <main className="dashboard-content">
        <div className="greeting">
          <h1>{greeting}, Dr. {doctor.name}</h1>
          <h2>Upcoming Appointments</h2>
          
        </div>
      </main>
    </div>
  );
};

export default DoctorDashboard;
